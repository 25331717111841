import React, { useState, useEffect } from "react"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import { NavLink } from "react-router-dom"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"
import "./margin-attribution.scss"

// UI imports
import Tile from "../../components/structure/tile/tile"
import Title from "../../components/structure/title/title"
import Select from "../../components/ui/select/select"
import Button from "../../components/ui/button/button"
import Table from "../../components/structure/table/table"
import Badge from "../../components/ui/badge/badge"

// Build up a list of years for the select field
let yearsObj = {}
let nextYear = Number(moment().startOf("year").add(2, "years").format("YYYY"))
for (let i = nextYear; i >= 2018; i--) {
	yearsObj = {
		[i]: i,
		...yearsObj,
	}
}

// Returns a dashboard for viewing the margins value associated with each channel
export default function MarginAttribution() {
	const [loading, setLoading] = useState(false)
	const [bookings, setBookings] = useState([])
	const [attributionSummary, setAttributionSummary] = useState({})

	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")

	// Add new useEffect to calculate attribution summary when bookings change
	useEffect(() => {
		if (bookings.length === 0) return

		const summary = bookings.reduce((acc, booking) => {
			const source = booking?.tracking?.utm_source || "No Source"
			const campaign = booking?.tracking?.utm_campaign || "No Campaign"

			if (!acc[source]) {
				acc[source] = {
					totalProfit: 0,
					totalRevenue: 0,
					campaigns: {},
				}
			}

			if (!acc[source].campaigns[campaign]) {
				acc[source].campaigns[campaign] = {
					totalProfit: 0,
					totalRevenue: 0,
					bookingCount: 0,
				}
			}

			acc[source].totalProfit += booking?.margins?.profit || 0
			acc[source].totalRevenue += booking?.margins?.customer_price || 0
			acc[source].campaigns[campaign].totalProfit += booking?.margins?.profit || 0
			acc[source].campaigns[campaign].totalRevenue += booking?.margins?.customer_price || 0
			acc[source].campaigns[campaign].bookingCount += 1

			return acc
		}, {})

		setAttributionSummary(summary)
	}, [bookings])

	const findBookings = async () => {
		// Reset the state
		setLoading(true)

		// Get the milliseconds timestamps from the chosen filters
		let fetchStart = 0
		let fetchEnd = 0
		if (month && year) {
			fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		} else if (!month && year) {
			fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
			fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		}
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		try {
			// Find all the bookings where the created date is within the range
			const bookingsSnapshot = await db.collection("bookings").where("created", ">=", startTimestamp).where("created", "<=", endTimestamp).get()

			// Setup some arrays for the booking data and query promises
			const bookingsArray = []
			const promises = []

			// Map over each of the booking docs that were retruned
			bookingsSnapshot.forEach((booking) => {
				const bookingID = booking.id
				const bookingData = booking.data()

				// Build a promise chain to get booking enquiry details
				const fetchEnquiryDetails = async () => {
					let agentData = {}

					// Fetch the agent data
					if (bookingData.agent) {
						const agentDoc = await db.collection("users").doc(bookingData.agent).get()
						if (agentDoc.exists) {
							agentData = {
								id: agentDoc.id,
								...agentDoc.data(),
							}
						}
					}

					// Find the original enquiry information from the removed collection
					let enquiryData = null
					let trackingData = null
					if (bookingData.client && bookingData.enquiry?.created) {
						const enquiryDocs = await db
							.collection("enquiries_removed") //
							.where("enquiry.client", "==", bookingData.client)
							.where("enquiry.created", "==", bookingData.enquiry?.created)
							.limit(1)
							.get()

						// Get the enquiry data
						enquiryDocs.forEach((enquiry) => {
							enquiryData = enquiry.data()?.enquiry

							if (typeof enquiry.data()?.enquiry?.tracking !== "undefined" && enquiry.data()?.enquiry?.tracking) {
								trackingData = enquiry.data()?.enquiry?.tracking
							}
						})
					}

					// Format both the customer price and profit to 2 decimal places
					const customerPriceFormatted = new Intl.NumberFormat("en-GB", {
						style: "currency",
						currency: "GBP",
					}).format(bookingData.margins?.customer_price)
					const profitFormatted = new Intl.NumberFormat("en-GB", {
						style: "currency",
						currency: "GBP",
					}).format(bookingData.margins?.profit)

					// Return a single object combining everything we care about
					return {
						bookingID,
						...bookingData,
						agent: agentData,
						enquiry: enquiryData,
						tracking: trackingData,
						margins: {
							customer_price: bookingData.margins?.customer_price,
							customer_price_formatted: customerPriceFormatted,
							profit: bookingData.margins?.profit,
							profit_formatted: profitFormatted,
						},
					}
				}

				// Push the fetch function into our promises array
				promises.push(fetchEnquiryDetails())
			})

			// Wait until all booking & agent fetches are done
			const results = await Promise.all(promises)

			// Push them into our bookingsArray
			bookingsArray.push(...results)

			// Set into state or do something similar:
			setBookings(bookingsArray)
		} catch (err) {
			console.log(err)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Margin Attribution</h1>
			</Title>

			<div className="page-filters extra-stats">
				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={yearsObj}
				/>

				<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
					<Button
						filters={true}
						disabled={(!month || !year) && !year}
						label="Fetch stats"
						loadingText="Fetching stats..."
						loading={loading}
						onClick={() => findBookings()}
					/>
				</div>
			</div>

			<Table
				className="travellers-table"
				headings={["Source", "Source Total", "Campaigns"]}
				noResults={Object.keys(attributionSummary).length === 0}
				noResultsMessage={"No results matching those filters"}>
				{Object.entries(attributionSummary)
					.sort(([, a], [, b]) => b.totalProfit - a.totalProfit)
					.map(([source, data]) => (
						<tr key={source}>
							<td>{source}</td>
							<td>{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(data.totalProfit)}</td>
							<td>
								<ul className="medium-list">
									{Object.entries(data.campaigns)
										.sort(([, a], [, b]) => b.totalProfit - a.totalProfit)
										.map(([campaign, stats], index) => (
											<li key={`${source}-${campaign}`}>
												{campaign} <small>({new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(stats.totalProfit)})</small>
											</li>
										))}
								</ul>
							</td>
						</tr>
					))}
				{Object.keys(attributionSummary).length > 0 && (
					<tr className="totals-row">
						<td>
							<strong>Total (excluding No Source)</strong>
						</td>
						<td>
							{new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" }).format(
								Object.entries(attributionSummary)
									.filter(([source]) => source !== "No Source")
									.reduce((total, [_, data]) => total + data.totalProfit, 0)
							)}
						</td>
						<td></td>
					</tr>
				)}
			</Table>

			<br />
			<br />
			<br />

			<Table
				className="travellers-table"
				headings={["Site", "Booked", "Agent", "Has Tracking", "UTM Source", "UTM Campaign", "UTM Medium", "UTM Content", "UTM Term", "Customer Price", "Sales Margin", ""]}
				noResults={Object.entries(bookings).length === 0}
				noResultsMessage={"No results matching those filters"}>
				{bookings.map((booking) => (
					<tr
						key={booking.bookingID}
						data-booking-id={booking.bookingID}>
						<td>{booking.enquiry?.site}</td>
						<td>{moment(booking.created?.seconds, "X").format("MMM D[,] YYYY")}</td>
						<td>
							{booking?.agent?.first_name} {booking?.agent?.last_name}
						</td>
						<td>
							{booking.tracking && (
								<Badge
									label="Yes"
									type="POSITIVE"
								/>
							)}

							{!booking.tracking && (
								<Badge
									label="No"
									type="NEGATIVE"
								/>
							)}
						</td>
						<td>{booking?.tracking?.utm_source || <small className="no-value">-</small>}</td>
						<td>{booking?.tracking?.utm_campaign || <small className="no-value">-</small>}</td>
						<td>{booking?.tracking?.utm_medium || <small className="no-value">-</small>}</td>
						<td>{booking?.tracking?.utm_content || <small className="no-value">-</small>}</td>
						<td>{booking?.tracking?.utm_term || <small className="no-value">-</small>}</td>
						<td>{booking?.margins?.customer_price_formatted}</td>
						<td>{booking?.margins?.profit_formatted}</td>
						<td className="is-button">
							<NavLink to={`booking/${booking.bookingID}`}>View booking</NavLink>
							<a
								target="_blank"
								rel="noreferrer"
								href={`booking/${booking.bookingID}`}>
								View in new tab
							</a>
						</td>
					</tr>
				))}
			</Table>
		</Tile>
	)
}
