import React from "react"

/**
 * Auth provider
 */
import { AuthProvider } from "./providers/auth"

/**
 * Alerts provider & components
 */
import { AlertsProvider } from "./providers/alerts"
import Alerts from "../components/app/alerts/alerts"

/**
 * Modal provider & component
 */
import { ModalProvider } from "./providers/modal"
import Modal from "../components/app/modal/modal"
import Cursor from "../components/app/cursor/cursor"

/**
 * Functional component wrapping all children passed in with various context providers
 */
function AppContexts(props) {
	return (
		<>
			<AuthProvider>
				<AlertsProvider>
					<ModalProvider>
						{/* Children elements to sit beneath the context providers */}
						{props.children}

						{/* App alerts wrapper */}
						<div className="app-alerts-wrapper">
							<Alerts />
						</div>

						{/* App modal wrapper */}
						<div className="app-modal-wrapper">
							<Modal />
						</div>

						<Cursor />
					</ModalProvider>
				</AlertsProvider>
			</AuthProvider>
		</>
	)
}

export default AppContexts
