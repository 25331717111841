import React, { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { db } from "../../utils/firebase"
import moment from "moment"

// UI components
import Badge from "../ui/badge/badge"

// Booking table row for the suppliers stats page
export default function Booking(props) {
	const [client, setClient] = useState({})
	const [agent, setAgent] = useState({})
	const [supplierPrice, setSupplierPrice] = useState(0)
	const [supplierDetails, setSupplierDetails] = useState({})

	// Deconstruct the details from the props
	const { id, details, sorting, supplier } = props

	// Generate a string to represent the check_in date from the timestamps
	const checkInDate = moment(details.check_in?.seconds, "X").format("MMM D[,] YYYY")
	const bookedDate = moment(details.booked?.seconds, "X").format("MMM D[,] YYYY")

	// Split the supplier by an underscore
	const supplierParts = supplier.split("_")

	// Pull both the type and ID from the supplier parts
	const supplierType = supplierParts[0]
	const supplierID = supplierParts[1]

	// On component load
	useEffect(() => {
		// Get the client record from the database
		db.doc(`clients/${details.client}`)
			.get()
			.then((clientDoc) => {
				// Set the client data into the local state
				setClient(clientDoc.data())
			})

		// Get the agent record from the database
		db.doc(`users/${details.agent}`)
			.get()
			.then((agentDoc) => {
				// Set the agent data into the local state
				setAgent(agentDoc.data())
			})
	}, [])

	// When the supplier type, ID or booking ID is updated
	useEffect(() => {
		// Process the relevant channel for the
		if (supplierType === "HOTEL") {
			fetchHotelDocuments()
		} else if (supplierType === "COURSE") {
			fetchCourseDocuments()
		} else if (supplierType === "TRANSFER") {
			fetchTransferDocuments()
		}
	}, [id, supplierType, supplierID])

	// When the supplierType or supplierID is updated
	useEffect(() => {
		// Pull the supplier details
		if (supplierType === "HOTEL") {
			db.doc(`hotels/${supplierID}`)
				.get()
				.then((supplierDoc) => {
					// Push the supplier data into the state
					setSupplierDetails({ ...supplierDoc.data() })
				})
		} else if (supplierType === "COURSE") {
			db.doc(`courses/${supplierID}`)
				.get()
				.then((supplierDoc) => {
					// Push the supplier data into the state
					setSupplierDetails({ ...supplierDoc.data() })
				})
		} else if (supplierType === "TRANSFER") {
			db.doc(`transfers/${supplierID}`)
				.get()
				.then((supplierDoc) => {
					// Push the supplier data into the state
					setSupplierDetails({ ...supplierDoc.data() })
				})
		}
	}, [supplierType, supplierID])

	// Fetch the suppliers data if they're a hotel
	const fetchHotelDocuments = async () => {
		// Pull all the hotels under this booking
		await db
			.collection(`bookings/${id}/hotels`)
			.where("resortID", "==", supplierID)
			.get()
			.then((bookingDocs) => {
				// Create an empty array for storing the bookings
				let documentsArr = []
				let supplierCost = 0

				// Loop through the bookings that have been found that match
				bookingDocs.forEach((bookingDoc) => {
					// Pull some data from the booking document
					const { total_cost } = bookingDoc.data()

					// Add the cost onto the running total
					supplierCost = supplierCost + total_cost

					// Push the booking data into the array
					documentsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
				})

				// Set the bookings into the state
				setSupplierPrice(supplierCost)
				props.updateSupplierPrice(supplierCost, supplierType)
				props.updateGolfers(details.golfers)
				props.updateNonGolfers(details.non_golfers)
			})
	}

	// Fetch the suppliers data if they're a course
	const fetchCourseDocuments = async () => {
		// Pull all the bookings by their travel date
		await db
			.collection(`bookings/${id}/rounds`)
			.where("courseID", "==", supplierID)
			.get()
			.then((bookingDocs) => {
				// Create an empty array for storing the bookings
				let documentsArr = []
				let supplierCost = 0

				// Loop through the bookings that have been found that match
				bookingDocs.forEach((bookingDoc) => {
					// Pull some data from the booking document
					const { cost_per_golfer } = bookingDoc.data()

					// Add the cost onto the running total
					supplierCost = supplierCost + details.golfers * cost_per_golfer

					// Push the booking data into the array
					documentsArr.push({ id: bookingDoc.id, ...bookingDoc.data() })
				})

				// Set the bookings into the state
				setSupplierPrice(supplierCost)
				props.updateSupplierPrice(supplierCost, supplierType)
				props.updateGolfers(details.golfers)
				props.updateNonGolfers(details.non_golfers)
			})
	}

	// Fetch the suppliers data if they're a transfer
	const fetchTransferDocuments = () => {
		// Pull the margins for the booking from the details
		const { margins } = details

		// Set the bookings into the state
		setSupplierPrice(margins?.transfers_cost || 0)
		props.updateSupplierPrice(margins?.transfers_cost || 0)
		props.updateGolfers(details.golfers)
		props.updateNonGolfers(details.non_golfers)
	}

	return (
		<tr>
			<td>
				{supplierPrice.toLocaleString() === "0" && <div className="no-values-tag" />}

				<NavLink to={`booking/${id}`}>{details.reference}</NavLink>
			</td>
			<td>
				{agent.first_name && `${agent.first_name} ${agent.last_name}`}
				{!agent.first_name && <small className="no-value">No name set</small>}
			</td>
			{sorting === "booked_date" && <td>{bookedDate}</td>}
			{sorting === "travel_date" && <td>{checkInDate}</td>}
			<td>
				{client.first_name && `${client.first_name} ${client.last_name}`}
				{!client.first_name && <small className="no-value">No name set</small>}
			</td>
			<td>{details.golfers}</td>
			<td>{details.non_golfers}</td>
			<td>
				{details.confirmed && (
					<Badge
						label="Yes"
						type="POSITIVE"
					/>
				)}

				{!details.confirmed && (
					<Badge
						label="No"
						type="NEGATIVE"
					/>
				)}
			</td>
			<td>
				{supplierType} {supplierDetails.name}
			</td>
			<td>£{supplierPrice.toLocaleString()}</td>
			<td>
				{details.resorts_paid && (
					<Badge
						label="Yes"
						type="POSITIVE"
					/>
				)}

				{!details.resorts_paid && (
					<Badge
						label="No"
						type="NEGATIVE"
					/>
				)}
			</td>
			<td className="is-button">
				<NavLink to={`booking/${id}`}>View booking</NavLink>
			</td>
		</tr>
	)
}
