import React, { useEffect, useState, createContext } from "react"
import { db, auth } from "../firebase"
import firebase from "firebase"
import moment from "moment"

/**
 * Log the user in with the provided credentials
 *
 * @param {string} username
 * @param {string} password
 */
const loginRequest = async (username, password) => {
	return firebase
		.auth()
		.signInWithEmailAndPassword(username, password)
		.then(() => {
			return {
				authenticated: true,
			}
		})
		.catch((error) => {
			return error
		})
}

/**
 * Log the user out
 */
const logoutRequest = () => {
	firebase
		.auth()
		.signOut()
		.then(() => {
			window.location.href = "/"
		})
}

/**
 * Setup a context for storing the user object
 */
const AuthContext = createContext()

/**
 * Creating the auth provider to wrap the application in for providing the user object
 *
 * @param {Object} params
 * @returns Authentication provider to wrap the application in
 */
const AuthProvider = ({ children }) => {
	const [user, setUser] = useState({})
	const [dailyBookings, setDailyBookings] = useState([])

	useEffect(() => {
		/**
		 * Set a loading boolean
		 */
		setUser({
			loading: true,
			logged_in: false,
		})
		/**
		 * Listen for changes on the auth
		 */
		const unsubscribe = auth.onAuthStateChanged((user) => {
			/**
			 * Get the last sign in date for the user
			 */
			const { lastSignInTime } = user?.metadata || {}
			/**
			 * Get the unix timestamp for the last sign in time
			 */
			const lastSignIn = moment(lastSignInTime).valueOf()
			/**
			 * Get the unix timestamp for 8 hours ago
			 */
			const sessionTimeout = moment().subtract(8, "hours").valueOf()
			/**
			 * If the sign in time is older than the session timeout, we need to log the user
			 * out and have them re-login in
			 */
			if (lastSignIn < sessionTimeout) {
				logoutRequest()
			} else {
				if (user) {
					db.doc(`users/${user.uid}`).onSnapshot((userSnap) => {
						setUser({
							uid: user.uid,
							logged_in: true,
							...userSnap.data(),
							loading: false,
							show_donut: userSnap.data().show_for.targets,
						})
					})
				} else {
					setUser({
						logged_in: false,
						loading: false,
					})
				}
			}
		})
		/**
		 * Remove the listener on component unload
		 */
		return () => unsubscribe()
	}, [])

	useEffect(() => {
		if (user?.uid) {
			// Get the start and end of today
			const rangeDailyStart = moment().startOf("day").valueOf()
			const rangeDailyEnd = moment().endOf("day").valueOf()
			const startTimestamp = firebase.firestore.Timestamp.fromMillis(rangeDailyStart)
			const endTimestamp = firebase.firestore.Timestamp.fromMillis(rangeDailyEnd)

			// Listen for changes to the bookings where the agent
			const unsubscribe = db
				.collection("bookings")
				.where("agent", "==", user.uid)
				.where("booked", ">=", startTimestamp)
				.where("booked", "<=", endTimestamp)
				.onSnapshot((bookingDocs) => {
					bookingDocs.docChanges().forEach((change) => {
						const { booked, margins } = change.doc.data()
						const bookingAsMillis = moment.unix(booked?.seconds).valueOf()

						if (change.type === "added") {
							if (bookingAsMillis >= rangeDailyStart && bookingAsMillis < rangeDailyEnd) {
								setDailyBookings((dailyBookings) => [
									...dailyBookings,
									{
										id: change.doc.id,
										...margins,
									},
								])
							}
						}

						if (change.type === "modified") {
							if (bookingAsMillis >= rangeDailyStart && bookingAsMillis < rangeDailyEnd) {
								setDailyBookings((dailyBookings) => {
									let updatedDailyBookings = [...dailyBookings]
									for (let i in dailyBookings) {
										if (dailyBookings[i].id === change.doc.id) {
											updatedDailyBookings[i] = {
												id: change.doc.id,
												...margins,
											}
											break
										}
									}
									return updatedDailyBookings
								})
							}
						}

						if (change.type === "removed") {
							setMonthlyBookings((monthlyBookings) => {
								monthlyBookings.filter((booking) => booking.id !== change.doc.id)
							})
						}
					})
				})

			return () => unsubscribe()
		}
	}, [user])

	return <AuthContext.Provider value={{ user, dailyBookings }}>{children}</AuthContext.Provider>
}

export { loginRequest, logoutRequest, AuthContext, AuthProvider }
