import { useContext } from "react"
import { AuthContext } from "../../../utils/providers/auth"
import DonutCursor from "../../../assets/images/donut.png"

export default function Cursor() {
	const { user, dailyBookings } = useContext(AuthContext)

	// If they are not on the leaderboard - show the donut
	if (!user.show_donut) return <></>

	if (dailyBookings.length === 0) {
		// Change the cursor to a loading spinner
		document.body.style.cursor = `url(${DonutCursor}) 11 11, auto`
	} else if (dailyBookings.length > 0) {
		// Set the cursor to default
		document.body.style.cursor = "default"
	}

	return <></>
}
