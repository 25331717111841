import styles from "./flights.module.scss"

import { useState, useContext } from "react"
import { BookingContext } from "../../../utils/providers/booking"
import { AlertsContext } from "../../../utils/providers/alerts"
import Airports from "./_utils/airports.json"
import Airlines from "./_utils/airlines.json"
import { db, auth } from "../../../utils/firebase"
import firebase from "firebase"

// Utility helper functions
import { searchFlights, findAvailableSeats, completeFlightBooking } from "./_utils/flights"

// UI components
import SearchSelect from "../../../components/ui/select/search-select"
import Passengers from "./_components/passengers/passengers"
import { WarningTriangleIcon } from "../../../utils/svgs-v2"
import Configure from "./_components/configure/configure"
import Button from "../../../components/ui/button/button"
import { LoadingIcon, SearchIcon } from "../../../utils/svgs"
import Flight from "./_components/flight/flight"
import FlightLight from "./_components/flight/flight-light"
import Input from "../../../components/ui/inputs/input"
import Select from "../../../components/ui/select/select"

// Exports the HTML markup for the flights tab on a booking
export default function Flights({ bookingFlight = null, expectedFlyers = 0, passengers = [] }) {
	const [loading, setLoading] = useState(false)
	const [booking, setBooking] = useState(false)
	const [activeTab, setActiveTab] = useState("FLIGHTS")
	const [fetchingSeats, setFetchingSeats] = useState(false)
	const [searchError, setSearchError] = useState(false)
	const [errors, setErrors] = useState(null)

	const [flights, setFlights] = useState([])
	const [basketID, setBasketID] = useState(null)
	const [sessionID, setSessionID] = useState(null)
	const [noFlights, setNoFlights] = useState(false)
	const [nightsOverride, setNightsOverride] = useState(null)

	const [chosenFlight, setChosenFlight] = useState(null)
	const [flightDetails, setFlightDetails] = useState(bookingFlight)
	const [availableSeats, setAvailableSeats] = useState({})
	const [availableExtras, setAvailableExtras] = useState([])

	const [departureAirports, setDepartureAirports] = useState([])
	const [destinationAirports, setDestinationAirports] = useState([])

	// Required parameters from global contexts
	const { bookingID, flightsBooked, setFlightsBooked, flightsReference, setFlightsReference, saveBookingDetails } = useContext(BookingContext)
	const { pushAlert } = useContext(AlertsContext)

	// Find the airline name from their supplier code
	const airlineName = flightDetails ? Object.keys(Airlines).find((key) => Airlines[key].includes(flightDetails?.supplier)) : null

	// Setup a new object to store the airports
	const transformedAirports = {}

	// Map over the whole list
	for (const airportKey in Airports) {
		// Get the airport details
		const airport = Airports[airportKey]

		// If they have an international code
		if (airport.iata) {
			// Write them into the new object
			transformedAirports[airport.iata] = `${airport.name}, ${airport.country}`
		}
	}

	// Make a flight search request into PaxPort
	const searchForFlights = () => {
		// Toggle the loading state
		setLoading(true)
		setFlights([])
		setNoFlights(false)
		setSearchError(false)

		// If the airports are not set, return an error
		if (departureAirports.length === 0 || destinationAirports.length === 0) {
			pushAlert({
				type: "ALERT",
				title: "Missing information",
				body: "Please select the departure and destination airports before searching.",
			})
			setLoading(false)
			return
		}

		// Make a request to PaxPort through our cloud function
		searchFlights({
			bookingID,
			departure_airports: departureAirports,
			destination_airports: destinationAirports,
			nights_override: nightsOverride ? parseInt(nightsOverride) : null,
		}).then(({ sessionID = null, flights = [] }) => {
			// If our search was successful
			if (sessionID && flights.length > 0) {
				setSessionID(sessionID)
				setFlights(flights)
			} else if (sessionID && flights.length === 0) {
				setNoFlights(true)
			} else {
				setSearchError(true)
			}

			// Toggle the loading state
			setLoading(false)
		})
	}

	// Choose the flight option and begin to find availabel seats & extras
	const chooseFlightOption = async (flight) => {
		// Set the loading state
		setFetchingSeats(true)
		setChosenFlight(flight.itineraryID)

		// Find the available seats via PaxPort
		findAvailableSeats({
			bookingID,
			sessionID,
			itineraryID: flight.itineraryID,
		}).then(async ({ seats = {}, extras = [], basketID }) => {
			// Set the details into the state
			setFlightDetails(flight)
			setAvailableSeats(seats)
			setAvailableExtras(extras)
			setFetchingSeats(false)
			setBasketID(basketID)

			// Write the flight into the booking
			await db.doc(`bookings/${bookingID}`).set(
				{
					flight: {
						sessionID: sessionID,
						basketID: basketID,
						itineraryID: flight.itineraryID,
						...flight,
					},
				},
				{ merge: true }
			)

			await db.collection(`bookings/${bookingID}/logs`).add({
				type: "FLIGHTS_SEARCH_STARTED",
				message: `A search for flights was started.`,
				created: firebase.firestore.FieldValue.serverTimestamp(),
				badge: "INFO",
				user: auth.currentUser.uid,
			})
		})
	}

	// Confirm the booking with PaxPort
	const confirmBooking = async () => {
		// Set the loading state
		setBooking(true)

		// Send the booking details off to PaxPort for processing
		completeFlightBooking({
			bookingID,
			sessionID,
			basketID,
			itineraryID: flightDetails.itineraryID,
			flight: flightDetails,
		}).then(async ({ result, stage = "", totalCost, bookingRef }) => {
			// If the request was successful, update the booking status
			if (result && stage === "booked") {
				await db.doc(`bookings/${bookingID}`).set(
					{
						flights_booked: true,
						flights_reference: bookingRef,
						flight: {
							status: "BOOKING_CONFIRMED",
						},
						margins: {
							flights_cost: parseFloat(totalCost),
						},
					},
					{ merge: true }
				)

				await db
					.doc(`bookings/${bookingID}`)
					.get()
					.then((doc) => {
						const { flight } = doc.data()
						setFlightDetails(flight)
					})

				await db.collection(`bookings/${bookingID}/logs`).add({
					type: "FLIGHTS_BOOKED",
					message: `Flights were booked and confirmed via PaxPort with reference: ${bookingRef}`,
					created: firebase.firestore.FieldValue.serverTimestamp(),
					badge: "SUCCESS",
					user: auth.currentUser.uid,
				})

				pushAlert({
					type: "SUCCESS",
					title: "Booking Complete",
					body: "The booking was completed with PaxPort.",
				})
			} else {
				db.doc(`bookings/${bookingID}`).set(
					{
						flight: {
							status: "BOOKING_REJECTED",
						},
					},
					{ merge: true }
				)

				pushAlert({
					type: "ALERT",
					title: "Booking Error",
					body: "There was an error processing the booking with PaxPort, try choosing different seats or cancelling and trying again.",
				})
			}

			// Reset the loading state
			setBooking(false)
		})
	}

	// If the user wants to cancel the current flight option
	const cancelChoice = () => {
		// Reset the state variables
		setFlightDetails(null)
		setAvailableSeats(null)
		setAvailableExtras(null)
		setChosenFlight(null)
		setErrors(null)

		// Delete the flight field from the booking
		db.doc(`bookings/${bookingID}`).set(
			{
				flights_booked: false,
				flights_reference: "",
				flight: firebase.firestore.FieldValue.delete(),
			},
			{ merge: true }
		)

		// Then delete the 'legs' field from each passenger
		passengers.forEach((passenger) => {
			db.doc(`bookings/${bookingID}/passengers/${passenger.id}`).set(
				{
					legs: firebase.firestore.FieldValue.delete(),
				},
				{ merge: true }
			)
		})

		db.collection(`bookings/${bookingID}/logs`).add({
			type: "FLIGHTS_CANCELLED",
			message: `The flights booking was cancelled.${flightsReference ? ` Previous reference was: ${flightsReference}` : ""}`,
			created: firebase.firestore.FieldValue.serverTimestamp(),
			badge: "WARNING",
			user: auth.currentUser.uid,
		})
	}

	return (
		<div className={styles.container}>
			<table className="booking-table">
				<tbody>
					<tr>
						<td>Flights booked</td>
						<td>
							<Select
								placeholder="Choose status:"
								value={flightsBooked ? "Yes" : "No"}
								activeOnHover={true}
								onSelect={(option) => setFlightsBooked(option.option === "true" ? true : false)}
								options={{
									true: "Yes",
									false: "No",
								}}
							/>
						</td>
					</tr>
					<tr>
						<td>Booking reference</td>
						<td>
							<Input
								placeholder="e.g. EZY-SJ4D72VY"
								value={flightsReference}
								onChange={setFlightsReference}
								activeOnHover={true}
								onBlur={() => saveBookingDetails()}
							/>
						</td>
					</tr>
				</tbody>
			</table>

			<br />

			<div className={styles.menu}>
				<div
					className={[styles.item, activeTab === "FLIGHTS" ? styles.isActive : ""].join(" ")}
					onClick={() => setActiveTab("FLIGHTS")}>
					{!bookingFlight ? "Flights" : "Flight Details"}
				</div>
				<div
					className={[styles.item, activeTab === "PASSENGERS" ? styles.isActive : ""].join(" ")}
					onClick={() => setActiveTab("PASSENGERS")}>
					Passengers{" "}
					<span className={passengers?.length === expectedFlyers ? styles.isComplete : ""}>
						{passengers?.length}/{expectedFlyers}
					</span>
				</div>
			</div>

			{activeTab === "FLIGHTS" && (
				<>
					{!flightDetails && (
						<div className={[styles.tab, activeTab === "FLIGHTS" ? styles.isActive : ""].join(" ")}>
							<div className={styles.search}>
								<SearchSelect
									className={styles.input}
									placeholder="Departing from:"
									icon={<SearchIcon />}
									activeOnHover={true}
									note={transformedAirports[departureAirports]}
									onSelect={(option) => {
										if (option.option === "LDNANY") {
											setDepartureAirports(["LGW", "LHR", "STN"])
										} else {
											setDepartureAirports([option.option])
										}
									}}
									withKey={true}
									options={transformedAirports}
								/>

								<SearchSelect
									className={styles.input}
									placeholder="Arriving at:"
									icon={<SearchIcon />}
									activeOnHover={true}
									note={transformedAirports[destinationAirports]}
									onSelect={(option) => {
										if (option.option === "LDNANY") {
											setDestinationAirports(["LGW", "LHR", "STN"])
										} else {
											setDestinationAirports([option.option])
										}
									}}
									withKey={true}
									options={transformedAirports}
								/>

								<Input
									placeholder="Nights:"
									value={nightsOverride}
									onChange={setNightsOverride}
								/>

								<div className={styles.maxHeight}>
									<Button
										label="Search for flights"
										onClick={searchForFlights}
										small={true}
										loading={loading}
										loadingText="Fetching flights..."
									/>
								</div>
							</div>

							{noFlights && (
								<div className={styles.noResults}>
									<p>There are no flights available for those parameters.</p>
								</div>
							)}

							{searchError && (
								<div className={styles.noResults}>
									<p>There was an issue, please check the booking and passenger details.</p>
								</div>
							)}

							{flights?.map((flight) => (
								<Flight
									key={flight.itineraryID}
									details={flight}
									chooseFlight={chooseFlightOption}
									loading={chosenFlight === flight.itineraryID}
								/>
							))}
						</div>
					)}

					{flightDetails && flightDetails.itineraryID && !flightDetails.status && (
						<div className={styles.tab}>
							{errors?.length > 0 && (
								<div className={styles.error}>
									<WarningTriangleIcon />

									<p>The session window for this booking has now closed, please cancel and proceed to try again.</p>

									<Button
										label="Cancel & return"
										onClick={cancelChoice}
										small={true}
									/>
								</div>
							)}

							{fetchingSeats && (
								<div className={styles.loading}>
									<p>Checking flight status...</p>
									<div className={styles.icon}>
										<LoadingIcon />
									</div>
								</div>
							)}

							{!fetchingSeats && !errors && (
								<>
									<Button
										type="secondary"
										label="Cancel & return"
										onClick={cancelChoice}
										small={true}
									/>

									<Configure
										bookingID={bookingID}
										flight={flightDetails}
										seats={availableSeats}
										extras={availableExtras}
										passengers={passengers}
										makeBooking={confirmBooking}
										booking={booking}
									/>
								</>
							)}
						</div>
					)}

					{flightDetails?.status && (
						<div className={styles.tab}>
							{flightDetails?.status === "BOOKING_REJECTED" && (
								<div className={styles.error}>
									<WarningTriangleIcon />

									<p>The session window for this booking has now closed, please cancel and proceed to try again.</p>

									<Button
										label="Cancel & return"
										onClick={cancelChoice}
										small={true}
									/>
								</div>
							)}

							{flightDetails?.status === "BOOKING_CONFIRMED" && (
								<div className={styles.error}>
									<p>Flights successfully booked with {airlineName} via PaxPort.</p>

									<FlightLight details={flightDetails} />

									<small className={styles.paxportDetails}>
										Session ID: {flightDetails?.sessionID}
										<br />
										Basket ID: {flightDetails?.basketID}
										<br />
										PaxPort Status: {flightDetails?.status}
									</small>
								</div>
							)}
						</div>
					)}
				</>
			)}

			{activeTab === "PASSENGERS" && (
				<div className={[styles.tab, activeTab === "PASSENGERS" ? styles.isActive : ""].join(" ")}>
					<Passengers
						bookingID={bookingID}
						passengers={passengers}
						expected={expectedFlyers}
					/>
				</div>
			)}
		</div>
	)
}
