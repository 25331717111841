import { auth } from "../../../../utils/firebase"

// NavLink import from the react router library
import { NavLink } from "react-router-dom"

// Icon imports for the nav links
import {
	AgentBookingsIcon,
	AgentEnquiriesIcon,
	BalancesIcon,
	BookingsIcon,
	CancellationsIcon,
	ClientsIcon,
	ConverstionsIcon,
	EnquiriesIcon,
	GolfIcon,
	HolidaysIcon,
	LeaderboardIcon,
	OverdueDepositsIcon,
	PartnersIcon,
	QuotingIcon,
	SuppliersIcon,
	TargetsIcon,
	TravellersIcon,
	UsersIcon,
	TrashCanIcon,
	SwapIcon,
} from "../../../../utils/svgs-v2"
import { Car, ChartArea, ReceiptText, Activity, ChartNetwork, HandCoins, SquareStack, TrendingUpDownIcon } from "lucide-react"

// Returns the markup for the staff navigation
export default function StaffNavigation({ isAdmin, isManager, isCRMManager, hideTargets }) {
	return (
		<div className="nav-blocks-wrapper">
			{!isCRMManager && (
				<>
					<div className="nav-link-block">
						<NavLink
							to="/clients"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<ClientsIcon />
							</div>
							<span>Clients</span>
						</NavLink>

						<NavLink
							to="/quoting"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<QuotingIcon />
							</div>
							<span>Quoting</span>
						</NavLink>

						<NavLink
							to="/enquiries"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<EnquiriesIcon />
							</div>
							<span>Enquiries</span>
						</NavLink>

						<NavLink
							to="/bookings"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<BookingsIcon />
							</div>
							<span>Bookings</span>
						</NavLink>
					</div>

					<hr className="nav-link-block-seperator" />
				</>
			)}

			{isManager && !isAdmin && (
				<>
					<div className="nav-link-block">
						<div className="nav-link-block-title">Management</div>
						<NavLink
							to="/staff"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<AgentEnquiriesIcon />
							</div>
							<span>Agent Enquiries</span>
						</NavLink>
					</div>

					<hr className="nav-link-block-seperator" />
				</>
			)}

			{/* Should this user have access to see the admin only pages? */}
			{isAdmin && (
				<>
					<div className="nav-link-block">
						<div className="nav-link-block-title">Admin</div>

						{!hideTargets && (
							<NavLink
								to="/targets"
								className="nav-link-item"
								activeClassName="is-active">
								<div className="nav-link-item-icon">
									<TargetsIcon />
								</div>
								<span>Targets</span>
							</NavLink>
						)}

						<NavLink
							to="/staff"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<AgentEnquiriesIcon />
							</div>
							<span>Agent Enquiries</span>
						</NavLink>

						<NavLink
							to="/internal"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<UsersIcon />
							</div>
							<span>Users</span>
						</NavLink>

						<NavLink
							to="/gift-vouchers"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<BalancesIcon />
							</div>
							<span>Gift Vouchers</span>
						</NavLink>
					</div>

					<hr className="nav-link-block-seperator" />
				</>
			)}

			{/* Should this user have access to see the admin only pages? */}
			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"ndo3zxkvoMdYJYg7xyeqSQshDQw1",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"8lIXQuliWhPJXrgi0rM7dJ3e9kM2",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
			].includes(auth.currentUser.uid) && (
				<>
					<div className="nav-link-block">
						<div className="nav-link-block-title">Logging</div>

						<NavLink
							to="/deleted-enquiries"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<TrashCanIcon />
							</div>
							<span>Deleted Enquiries</span>
						</NavLink>

						<NavLink
							to="/enquiry-assignments"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<SwapIcon />
							</div>
							<span>Enquiry Assignments</span>
						</NavLink>

						<NavLink
							to="/quotes"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<QuotingIcon />
							</div>
							<span>All Quotes</span>
						</NavLink>
					</div>

					<hr className="nav-link-block-seperator" />
				</>
			)}

			{!isCRMManager && (
				<>
					<div className="nav-link-block">
						<div className="nav-link-block-title">Bookings</div>

						<NavLink
							to="/balances"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<BalancesIcon />
							</div>
							<span>Balances</span>
						</NavLink>

						<NavLink
							to="/deposits"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<OverdueDepositsIcon />
							</div>
							<span>Overdue Deposits</span>
						</NavLink>

						<NavLink
							to="/cancellations"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<CancellationsIcon />
							</div>
							<span>Cancellations</span>
						</NavLink>
					</div>
				</>
			)}

			<div className="nav-link-block with-top-margin">
				<div className="nav-link-block-title">Stats</div>

				{!isCRMManager && (
					<>
						<NavLink
							to="/stats"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<AgentBookingsIcon />
							</div>
							<span>Stats</span>
						</NavLink>

						<NavLink
							to="/charting"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<ChartArea color="#7f7f7f" />
							</div>
							<span>YoY Charting</span>
						</NavLink>

						<NavLink
							to="/flights"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<BookingsIcon />
							</div>
							<span>Flights</span>
						</NavLink>

						<NavLink
							to="/transfers"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<Car color="#7f7f7f" />
							</div>
							<span>Transfers</span>
						</NavLink>

						<NavLink
							to="/buggies"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<GolfIcon />
							</div>
							<span>Buggies</span>
						</NavLink>

						<NavLink
							to="/conversions"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<ConverstionsIcon />
							</div>
							<span>Conversions</span>
						</NavLink>

						{(isAdmin || auth.currentUser.uid === "V0EpXEeyopbFR8jR3AOflLSqeTM2") && (
							<NavLink
								to="/tracking"
								className="nav-link-item"
								activeClassName="is-active">
								<div className="nav-link-item-icon">
									<Activity color="#7f7f7f" />
								</div>
								<span>Tracking</span>
							</NavLink>
						)}
					</>
				)}

				{(isAdmin || isCRMManager || auth.currentUser.uid === "V0EpXEeyopbFR8jR3AOflLSqeTM2") && (
					<NavLink
						to="/utm-parameters"
						className="nav-link-item"
						activeClassName="is-active">
						<div className="nav-link-item-icon">
							<ChartNetwork color="#7f7f7f" />
						</div>
						<span>UTM Parameters</span>
					</NavLink>
				)}

				{!isCRMManager && (
					<>
						<NavLink
							to="/suppliers"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<SuppliersIcon />
							</div>
							<span>Suppliers</span>
						</NavLink>

						<NavLink
							to="/travellers"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<TravellersIcon />
							</div>
							<span>Travellers</span>
						</NavLink>

						{isAdmin && (
							<>
								<NavLink
									to="/client-receipts"
									className="nav-link-item"
									activeClassName="is-active">
									<div className="nav-link-item-icon">
										<ReceiptText color="#7f7f7f" />
									</div>
									<span>Client Receipts</span>
								</NavLink>

								<NavLink
									to="/supplier-receipts"
									className="nav-link-item"
									activeClassName="is-active">
									<div className="nav-link-item-icon">
										<HandCoins color="#7f7f7f" />
									</div>
									<span>Supplier Payments</span>
								</NavLink>

								<NavLink
									to="/currencies-owed"
									className="nav-link-item"
									activeClassName="is-active">
									<div className="nav-link-item-icon">
										<BalancesIcon />
									</div>
									<span>Currencies Owed</span>
								</NavLink>

								<NavLink
									to="/margin-attribution"
									className="nav-link-item"
									activeClassName="is-active">
									<div className="nav-link-item-icon">
										<SquareStack color="#7f7f7f" />
									</div>
									<span>Margin Attribution</span>
								</NavLink>

								<NavLink
									to="/booking-margins"
									className="nav-link-item"
									activeClassName="is-active">
									<div className="nav-link-item-icon">
										<TrendingUpDownIcon color="#7f7f7f" />
									</div>
									<span>Booking Margins</span>
								</NavLink>
							</>
						)}

						<NavLink
							to="/leaderboard"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<LeaderboardIcon />
							</div>
							<span>Leaderboard</span>
						</NavLink>
					</>
				)}
			</div>

			{/* Should this user have access to see the admin only pages? */}
			{[
				"CGOHCdUwFAOe13OSTY2D9qdO2LX2",
				"iUlTXjfx1OZEPBhPosh6rJt3CKe2",
				"7GFYd2EvA9OiZxcufgbz5CRcssu1",
				"fM61i6PTzzZPtCGolcx870r9cCo2",
				"L334Rwj0YiRWSuCZLlixoBbsxwl2",
				"9ChsmNaiYkdQGjm3JbEbqY6mIbR2",
				"Z6EM3kFyJ8QiJMzMsYm6TwrWaUH3",
				"FbeBRh8bIGb9L6aRLLJSttvutQ33",
				"p4VG2EgFFQS8fsaBc0d9peNWaD52",
				"ndo3zxkvoMdYJYg7xyeqSQshDQw1",
				"neW9eVfyCNW5ILndUxMwNrXQxJD3",
			].includes(auth.currentUser.uid) && (
				<>
					<div className="nav-link-block with-top-margin">
						<div className="nav-link-block-title">Management</div>

						<NavLink
							to="/manage/holidays"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<HolidaysIcon />
							</div>
							<span>Holidays</span>
						</NavLink>

						<NavLink
							to="/manage/courses"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<GolfIcon />
							</div>
							<span>Courses</span>
						</NavLink>

						<NavLink
							to="/partners"
							className="nav-link-item"
							activeClassName="is-active">
							<div className="nav-link-item-icon">
								<PartnersIcon />
							</div>
							<span>Partners</span>
						</NavLink>
					</div>
				</>
			)}
		</div>
	)
}
