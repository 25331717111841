import { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../utils/providers/auth"
import { CALENDAR_MONTHS } from "../../utils/exports/months"
import exportToExcel from "./_utils/export"
import { NavLink } from "react-router-dom"
import { db } from "../../utils/firebase"
import firebase from "firebase"
import moment from "moment"

// UI imports
import Title from "../../components/structure/title/title"
import Tile from "../../components/structure/tile/tile"
import Select from "../../components/ui/select/select"
import Button from "../../components/ui/button/button"
import Table from "../../components/structure/table/table"
import Badge from "../../components/ui/badge/badge"

// Build up a list of years for the select field
let yearsObj = {}
let nextYear = Number(moment().startOf("year").add(2, "years").format("YYYY"))
for (let i = nextYear; i >= 2018; i--) {
	yearsObj = {
		[i]: i,
		...yearsObj,
	}
}

// Returns the currencies owed page for admin users
export default function CurrenciesOwed() {
	const [loading, setLoading] = useState(false)
	const [bookings, setBookings] = useState([])
	const [invoices, setInvoices] = useState([])

	const [exportArr, setExportArr] = useState([])
	const [exportTitle, setExportTitle] = useState("")

	const [exportInvoicesArr, setExportInvoicesArr] = useState([])
	const [exportInvoicesTitle, setExportInvoicesTitle] = useState("")

	const [month, setMonth] = useState("")
	const [year, setYear] = useState("")

	// Pull the user object from the global context
	const { user } = useContext(AuthContext)

	// When the bookings are updated
	useEffect(() => {
		// Return early if there are no bookings
		if (bookings.length === 0 || !user?.can_export) return

		// Build a file name from the parameters
		setExportTitle(`CurrenciesOwed_Bookings_${month ? month : "All"}${year}`)
		setExportInvoicesTitle(`CurrenciesOwed_Invoices_${month ? month : "All"}${year}`)

		// Otherwise, loop through the bookings
		const exportData = bookings.map((booking) => {
			// Pull all the data from the booking object
			const { check_in_string, hotels_cost_gbp, rounds_cost_gbp, transfers_cost_gbp, transfer_currency, margins, currencies, resorts_paid, reference } = booking

			// Return the booking details
			return {
				booking_ref: reference,
				check_in: check_in_string,
				exchange_rate: margins.exchange_rate,
				hotels_currency: currencies?.hotels,
				hotels_cost_curr: margins.hotels_cost,
				hotels_cost_gbp,
				rounds_currency: currencies?.rounds,
				rounds_cost_curr: margins.rounds_cost,
				rounds_cost_gbp,
				transfers_currency: transfer_currency,
				transfers_cost_curr: margins.transfers_cost,
				transfers_cost_gbp,
				resorts_paid: resorts_paid,
			}
		})

		// Set the export data into the state
		setExportArr(exportData)

		// Setup the invoices export data
		const invoicesExportData = invoices.map((invoice) => {
			return {
				booking_ref: invoice.booking.reference,
				travel_date: invoice.booking.check_in_string,
				exchange_rate: invoice.booking.margins.exchange_rate,
				currency: invoice.currency,
				amount_curr: invoice.amount_paid,
				amount_gbp: invoice.amount_gbp,
				payment_status: invoice.payment_status,
			}
		})

		// Set the export data into the state
		setExportInvoicesArr(invoicesExportData)
	}, [bookings])

	// Query against the database to find all those bookings where the resorts_paid is false and has currency information
	const findCurrencyLiabilities = async () => {
		// Reset the state
		setLoading(true)

		// Get the milliseconds timestamps from the chosen filters
		let fetchStart = 0
		let fetchEnd = 0
		if (month && year) {
			fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		} else if (!month && year) {
			fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
			fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		}
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		try {
			// Fetch all payments in date range
			const querySnapshot = await db.collection("bookings").where("check_in", ">=", startTimestamp).where("check_in", "<=", endTimestamp).get()

			// Setup some arrays for the payments data and query promises
			const bookingsArray = []
			const promises = []

			// Map over each of the payment docs that were retruned
			querySnapshot.forEach((doc) => {
				const bookingID = doc.id
				const bookingData = doc.data()

				// Pull the currencies from the booking data
				const { currencies } = bookingData

				// Format the paid date into a string
				const checkInDate = moment(bookingData.check_in?.seconds, "X").format("MMM D[,] YYYY")

				// Fetch the hotels and rounds data
				const fetchHotelsAndRounds = async () => {
					const hotelsData = await db.collection("bookings").doc(bookingID).collection("hotels").get()
					const roundsData = await db.collection("bookings").doc(bookingID).collection("rounds").get()

					// If the currency for the hotels isn't GBP, we need to work out the GBP cost using the exchange rate
					let hotelsCostGBP = bookingData.margins.hotels_cost?.toFixed(2)
					if (currencies?.hotels !== "GBP") {
						hotelsCostGBP = (bookingData.margins.hotels_cost / bookingData.margins.exchange_rate).toFixed(2)
					}

					// If the currency for the rounds isn't GBP, we need to work out the GBP cost using the exchange rate
					let roundsCostGBP = bookingData.margins.rounds_cost?.toFixed(2)
					if (currencies?.rounds !== "GBP") {
						roundsCostGBP = (bookingData.margins.rounds_cost / bookingData.margins.exchange_rate).toFixed(2)
					}

					// Fetch the transfer partner
					let transferPartnerData = {}
					if (bookingData.transfer) {
						const transfersData = await db.collection("transfers").doc(bookingData.transfer).get()
						transferPartnerData = transfersData.data()
					}

					// If the currency for the transfers isn't GBP, we need to work out the GBP cost using the exchange rate
					let transfersCostGBP = bookingData.margins.transfers_cost?.toFixed(2)
					if (currencies?.transfers !== "GBP") {
						transfersCostGBP = (bookingData.margins.transfers_cost / bookingData.margins.exchange_rate).toFixed(2)
					}

					// Make sure the hotels, rounds and transfers costs are in fixed 2 decimal places
					hotelsCostGBP = Number(hotelsCostGBP).toFixed(2)
					roundsCostGBP = Number(roundsCostGBP).toFixed(2)
					transfersCostGBP = Number(transfersCostGBP).toFixed(2)

					// And also the figures from the margins
					bookingData.margins.hotels_cost = Number(bookingData.margins.hotels_cost).toFixed(2)
					bookingData.margins.rounds_cost = Number(bookingData.margins.rounds_cost).toFixed(2)
					bookingData.margins.transfers_cost = Number(bookingData.margins.transfers_cost).toFixed(2)

					return {
						bookingID,
						...bookingData,
						check_in_string: checkInDate,
						hotels: hotelsData,
						hotels_cost_gbp: hotelsCostGBP,
						rounds: roundsData,
						rounds_cost_gbp: roundsCostGBP,
						transfers_cost_gbp: transfersCostGBP,
						transfer_currency: transferPartnerData?.currency?.toUpperCase() || "N/A",
					}
				}

				// Return a single object combining everything we care about
				promises.push(fetchHotelsAndRounds())
			})

			// Wait until all booking & agent fetches are done
			const results = await Promise.all(promises)

			// Push them into our bookingsArray
			bookingsArray.push(...results)

			// Set into state or do something similar:
			setBookings(bookingsArray)
			findCurrencyLiabilitiesInvoices()
		} catch (err) {
			console.error(err)
		}
	}

	// Query against the database to find all those bookings where the resorts_paid is false and has currency information
	const findCurrencyLiabilitiesInvoices = async () => {
		// Get the milliseconds timestamps from the chosen filters
		let fetchStart = 0
		let fetchEnd = 0
		if (month && year) {
			fetchStart = moment(`${month}-${year}`, "MMMM-YYYY").startOf("month").valueOf()
			fetchEnd = moment(`${month}-${year}`, "MMMM-YYYY").endOf("month").valueOf()
		} else if (!month && year) {
			fetchStart = moment(`${year}`, "YYYY").startOf("year").valueOf()
			fetchEnd = moment(`${year}`, "YYYY").endOf("year").valueOf()
		}
		const startTimestamp = firebase.firestore.Timestamp.fromMillis(fetchStart)
		const endTimestamp = firebase.firestore.Timestamp.fromMillis(fetchEnd)

		try {
			// Get all the bookings in the date range
			const bookingsSnapshot = await db.collection("bookings").where("check_in", ">=", startTimestamp).where("check_in", "<=", endTimestamp).get()

			// Setup an array to store the supplier_invoices to map
			const supplierInvoices = []
			const suppliersPromises = []

			// Then check to see if the bookings have any docs under their "supplier_invoices" sub-collection
			bookingsSnapshot.forEach((doc) => {
				const bookingID = doc.id

				// Fetch the supplier invoices for the booking
				const fetchBookingSupplierInvoices = async () => {
					// Check to see if the booking has any docs under their "supplier_invoices" sub-collection
					const invoicesSnapshot = await db.collection("bookings").doc(bookingID).collection("supplier_invoices").get()

					// If there are any, we need to get the data for each of those docs
					if (invoicesSnapshot.docs.length > 0) {
						// Map over each of the docs and push them into the supplierInvoices array
						invoicesSnapshot.forEach((doc) => {
							supplierInvoices.push({ id: doc.id, ...doc.data(), bookingID: bookingID })
						})
					}

					return true
				}

				// Push the fetch function into our promises array
				suppliersPromises.push(fetchBookingSupplierInvoices())
			})

			// Wait until all booking & agent fetches are done
			await Promise.all(suppliersPromises)

			// Setup some arrays for the payments data and query promises
			const invoicesArray = []
			const promises = []

			// Map over each of the payment docs that were retruned
			supplierInvoices.forEach((doc) => {
				const paymentID = doc.id
				const paymentData = doc

				// Format the paid date into a string
				const paidDate = moment(paymentData.date_paid?.seconds, "X").format("MMM D[,] YYYY")

				// Get the booking reference
				const bookingRef = doc.bookingID

				// Build a promise chain to get booking data and agent data
				const fetchBookingDetails = async () => {
					let bookingData = {}

					// Fetch the booking data
					if (bookingRef) {
						const bookingDoc = await db.collection("bookings").doc(bookingRef).get()
						if (bookingDoc.exists) {
							// Format the date strings
							const checkInDate = moment(bookingDoc.data().check_in?.seconds, "X").format("MMM D[,] YYYY")
							const bookedDate = moment(bookingDoc.data().booked?.seconds, "X").format("MMM D[,] YYYY")

							// If all the currencies the same, and are not GBP, we need to work out the GBP cost using the exchange rate
							if (bookingDoc.data().currencies[0] !== "GBP") {
								const conversion = paymentData.amount / bookingDoc.data()?.margins?.exchange_rate
								paymentData.amount_gbp = isNaN(conversion) ? (0).toFixed(2) : conversion.toFixed(2)
							}

							bookingData = {
								id: bookingDoc.id,
								check_in_string: checkInDate,
								booked_string: bookedDate,
								bookingID: bookingDoc.id,
								...bookingDoc.data(),
							}
						}
					}

					// Return a single object combining everything we care about
					return {
						paymentID,
						...paymentData,
						amount_paid: isNaN(paymentData.amount?.toFixed(2)) ? (0).toFixed(2) : paymentData.amount?.toFixed(2),
						amount_gbp: paymentData.amount_gbp,
						paid_date_string: paidDate,
						booking: bookingData,
					}
				}

				// Push the fetch function into our promises array
				promises.push(fetchBookingDetails())
			})

			// Wait until all booking & agent fetches are done
			const results = await Promise.all(promises)

			// Push them into our invoicesArray
			invoicesArray.push(...results)

			// Set into state or do something similar:
			setInvoices(invoicesArray)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Tile fullPage={true}>
			<Title className="flex has-select-field">
				<h1>Currencies Owed</h1>
			</Title>

			<div className="page-filters extra-stats">
				<Select
					label="Month:"
					placeholder="Month:"
					value={month}
					activeOnHover={true}
					onSelect={(option) => setMonth(option.option)}
					options={CALENDAR_MONTHS}
				/>

				<Select
					label="Year:"
					placeholder="Year:"
					value={year}
					activeOnHover={true}
					onSelect={(option) => setYear(option.option)}
					options={yearsObj}
				/>

				<div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
					<Button
						filters={true}
						disabled={(!month || !year) && !year}
						label="Fetch stats"
						loadingText="Fetching stats..."
						loading={loading}
						onClick={() => findCurrencyLiabilities()}
					/>

					{user?.can_export && (
						<div>
							<Button
								filters={true}
								disabled={exportArr.length === 0}
								label="Export to XLSX"
								onClick={() => {
									exportToExcel(exportArr, exportTitle)

									setTimeout(() => {
										exportToExcel(exportInvoicesArr, exportInvoicesTitle)
									}, 2500)
								}}
							/>
						</div>
					)}
				</div>
			</div>

			<Table
				className="travellers-table"
				headings={[
					"Booking ref",
					"Travel date",
					"Exchange rate",
					"Hotels currency",
					"Hotels cost (CURR)",
					"Hotels cost (GBP)",
					"Rounds currency",
					"Rounds cost (CURR)",
					"Rounds cost (GBP)",
					"Transfers currency",
					"Transfers cost (CURR)",
					"Transfers cost (GBP)",
					"Resorts paid",
					"",
				]}
				noResults={Object.entries(bookings).length === 0}
				noResultsMessage={"No results matching those filters"}>
				{bookings.map((booking) => (
					<tr key={booking.bookingID}>
						<td>{booking.reference}</td>
						<td>{booking.check_in_string}</td>
						<td>{booking.margins.exchange_rate}</td>
						<td>{booking.currencies?.hotels}</td>
						<td>{booking.margins.hotels_cost}</td>
						<td>{booking.hotels_cost_gbp}</td>
						<td>{booking.currencies?.rounds}</td>
						<td>{booking.margins.rounds_cost}</td>
						<td>{booking.rounds_cost_gbp}</td>
						<td>{booking.transfer_currency}</td>
						<td>{booking.margins.transfers_cost}</td>
						<td>{booking.transfers_cost_gbp}</td>
						<td>
							{booking.resorts_paid && (
								<Badge
									label="Yes"
									type="POSITIVE"
								/>
							)}

							{!booking.resorts_paid && (
								<Badge
									label="No"
									type="NEGATIVE"
								/>
							)}
						</td>
						<td className="is-button">
							<NavLink to={`booking/${booking.bookingID}`}>View booking</NavLink>
							<a
								target="_blank"
								rel="noreferrer"
								href={`booking/${booking.bookingID}`}>
								View in new tab
							</a>
						</td>
					</tr>
				))}
			</Table>

			<br />
			<br />
			<br />

			<Table
				className="travellers-table"
				headings={["Booking ref", "Travel date", "Exchange rate", "Currency", "Amount (CURR)", "Amount (GBP)", "Payment Status", ""]}
				noResults={Object.entries(invoices).length === 0}
				noResultsMessage={"No individual invoices matching those filters"}>
				{invoices.map((invoice) => (
					<tr key={invoice.paymentID}>
						<td>{invoice.booking.reference}</td>
						<td>{invoice.booking.check_in_string}</td>
						<td>{invoice.booking.margins.exchange_rate}</td>
						<td>{invoice.currency}</td>
						<td>{invoice.amount_paid}</td>
						<td>{invoice.amount_gbp}</td>
						<td>{invoice.payment_status}</td>
						<td className="is-button">
							<NavLink to={`booking/${invoice.booking.bookingID}`}>View booking</NavLink>
							<a
								target="_blank"
								rel="noreferrer"
								href={`booking/${invoice.booking.bookingID}`}>
								View in new tab
							</a>
						</td>
					</tr>
				))}
			</Table>
		</Tile>
	)
}
