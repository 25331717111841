import { useEffect, useState } from "react"
import { db } from "../../../utils/firebase"

// ChartJS imports
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Tooltip } from "chart.js"

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip)

export default function MarginsLineChart({ tmStart, tmEnd, lyStart, lyEnd }) {
	const [tmBookings, setTmBookings] = useState([])
	const [lyBookings, setLyBookings] = useState([])

	const [thisMargin, setThisMargin] = useState(0)
	const [lastMargin, setLastMargin] = useState(0)

	// On load
	useEffect(() => {
		fetchThisMonth()
		fetchLastYear()
	}, [])

	// Fetch this months stats
	const fetchThisMonth = async () => {
		const bookings = await db
			.collection("bookings")
			.where("created", ">", tmStart)
			.where("created", "<", tmEnd)
			.get()
			.then((bookingDocs) => {
				let bookings = []
				let margin = 0
				bookingDocs.forEach((bookingDoc) => {
					bookings.push({ id: bookingDoc.id, ...bookingDoc.data() })
					margin += Math.round(bookingDoc.data().margins?.profit) || 0
				})
				setThisMargin(margin)
				return bookings
			})
		setTmBookings(bookings)
	}

	// Fetch last years stats
	const fetchLastYear = async () => {
		const bookings = await db
			.collection("bookings")
			.where("created", ">", lyStart)
			.where("created", "<", lyEnd)
			.get()
			.then((bookingDocs) => {
				let bookings = []
				let margin = 0
				bookingDocs.forEach((bookingDoc) => {
					bookings.push({ id: bookingDoc.id, ...bookingDoc.data() })
					margin += Math.round(bookingDoc.data().margins?.profit) || 0
				})
				setLastMargin(margin)
				return bookings
			})
		setLyBookings(bookings)
	}

	const processMargins = (data) => {
		const counts = {}

		data.forEach((booking) => {
			const date = new Date(booking.created.seconds * 1000)
			const normalizedDate = date.toLocaleDateString("en-GB", {
				day: "2-digit",
				month: "2-digit",
			})

			const profit = booking.margins?.profit || 0
			const roundedProfit = Math.round(profit)

			counts[normalizedDate] = (counts[normalizedDate] || 0) + roundedProfit
		})

		// Sort dates and calculate cumulative margin
		const sortedDates = Object.keys(counts).sort((a, b) => {
			const [dayA, monthA] = a.split("/").map(Number)
			const [dayB, monthB] = b.split("/").map(Number)
			return monthA === monthB ? dayA - dayB : monthA - monthB
		})

		const cumulativeMargins = {}
		let runningTotal = 0
		sortedDates.forEach((date) => {
			runningTotal += counts[date]
			cumulativeMargins[date] = runningTotal
		})

		return cumulativeMargins
	}

	const currentYearMargins = processMargins(tmBookings)
	const lastYearMargins = processMargins(lyBookings)

	// Get all unique dates across both datasets
	const allDates = [...new Set([...Object.keys(currentYearMargins), ...Object.keys(lastYearMargins)])].sort((a, b) => new Date(a) - new Date(b))

	// Get today as a string
	const today = new Date().toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
	})

	// Generate datasets for Chart.js
	const marginDatasetCurrentYear = allDates.map((date, index, dates) => {
		if (date > today) {
			return null
		}
		// If it's today and the value is 0, use yesterday's value
		if (date === today && (!currentYearMargins[date] || currentYearMargins[date] === 0)) {
			// Get yesterday's date from the dates array
			const yesterdayDate = dates[index - 1]
			return currentYearMargins[yesterdayDate] || 0
		}
		return currentYearMargins[date] || 0
	})
	const marginDatasetLastYear = allDates.map((date) => lastYearMargins[date] || 0)

	const calculateLinearTarget = (totalTarget, dates) => {
		const daysInMonth = dates.length
		const dailyTarget = totalTarget / daysInMonth

		// Generate cumulative target for each day
		const cumulativeTarget = dates.map((_, index) => Math.round(dailyTarget * (index + 1)))
		return cumulativeTarget
	}
	const linearTargetDataset = calculateLinearTarget(450000, allDates)

	const marginChartData = {
		labels: allDates,
		datasets: [
			{
				label: "This Year",
				data: marginDatasetCurrentYear,
				borderColor: "#e41f13",
				backgroundColor: "#e41f13",
				fill: false,
			},
			{
				label: "Last Year",
				data: marginDatasetLastYear,
				borderColor: "grey",
				backgroundColor: "grey",
				pointRadius: 2,
				borderDash: [5, 5],
				borderWidth: 2,
				fill: false,
			},
			{
				label: "Target Margin",
				data: linearTargetDataset,
				borderColor: "purple",
				backgroundColor: "purple",
				pointRadius: 0,
				borderDash: [10, 5],
				borderWidth: 1,
				fill: false,
			},
		],
	}

	const marginChartOptions = {
		responsive: true,
		animations: {
			y: {
				easing: "easeOutQuart",
				duration: 2000,
				from: (ctx) => {
					if (ctx.type === "data") {
						if (ctx.mode === "default" && !ctx.dropped) {
							ctx.dropped = true
							return ctx.chart.scales.y.bottom
						}
					}
					return ctx.chart.scales.y.bottom
				},
			},
			x: {
				duration: 0,
			},
		},
		plugins: {
			legend: {
				position: "top",
			},
			tooltip: {
				enabled: true,
				mode: "index",
				intersect: false,
				callbacks: {
					label: function (context) {
						const datasetIndex = context.datasetIndex
						const currentValue = context.raw
						const comparisonValue = datasetIndex === 0 ? context.chart.data.datasets[1].data[context.dataIndex] || 0 : context.chart.data.datasets[0].data[context.dataIndex] || 0

						const diff = currentValue - comparisonValue
						const percentageDiff = comparisonValue ? ((diff / comparisonValue) * 100).toFixed(2) : "N/A"

						const formattedValue = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(currentValue)
						const formattedDiff = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(diff)

						// Add custom message based on dataset
						if (datasetIndex === 0) {
							return `This Year: ${formattedValue} (${diff >= 0 ? "+" : ""}${formattedDiff}, ${percentageDiff !== "N/A" ? percentageDiff + "%" : "N/A"})`
						} else if (datasetIndex === 1) {
							return `Last Year: ${formattedValue}`
						} else {
							return `Target: ${formattedValue}`
						}
					},
					labelColor: () => {
						return {
							borderColor: "transparent",
							backgroundColor: "transparent",
						}
					},
				},
			},
		},
		interaction: {
			mode: "index",
			intersect: false,
		},
		scales: {
			x: {
				title: {
					display: true,
					text: "Dates (DD/MM)",
				},
			},
			y: {
				title: {
					display: true,
					text: "Cumulative Margin (Rounded)",
				},
			},
		},
	}

	// Custom plugin for drawing text above dots
	const customLabelsPlugin = {
		id: "customLabels",
		afterDatasetsDraw(chart) {
			const { ctx } = chart
			const currentYearMeta = chart.getDatasetMeta(0)
			const lastYearDataset = chart.data.datasets[1].data

			ctx.save()
			currentYearMeta.data.forEach((point, index) => {
				const currentValue = chart.data.datasets[0].data[index]
				const lastYearValue = lastYearDataset[index] || 0

				// Skip drawing if the value is 0
				if (!currentValue || currentValue === 0) return

				const diff = currentValue - lastYearValue
				const percentageDiff = lastYearValue === 0 ? "N/A" : ((diff / lastYearValue) * 100).toFixed(2)

				const formattedValue = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP", maximumFractionDigits: 0 }).format(currentValue)

				// Draw the value and difference above the dot
				const text = `${formattedValue} (${percentageDiff}%)`
				ctx.font = "10px Poppins"
				ctx.fillStyle = "black"
				ctx.textAlign = "center"
				ctx.fillText(text, point.x, point.y - 20)
			})
			ctx.restore()
		},
	}

	return (
		<div>
			<h2 style={{ fontSize: "18px", marginBottom: "4px" }}>Margin Trends</h2>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>This month: £{thisMargin}</p>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>Last year: £{lastMargin}</p>
			<p style={{ fontSize: "15px", marginBottom: "3px" }}>Difference: £{thisMargin - lastMargin}</p>
			<br />
			<div style={{ height: "675px", width: "100%" }}>
				<Line
					data={marginChartData}
					options={marginChartOptions}
					plugins={[customLabelsPlugin]}
				/>
			</div>
		</div>
	)
}
